import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PoModalComponent, PoTableAction, PoChartSerie, PoChartType, PoPageAction, PoModalAction } from '@po-ui/ng-components';
import { PoBreadcrumb, PoTableColumn, PoDynamicFormField } from '@po-ui/ng-components';
import { InstanciasService } from 'src/app/app.service';
import { ResultId } from 'src/app/class/ResultId';

@Component({
  selector: 'app-dynamic-search-page',
  templateUrl: './dynamic-search-page.component.html',
  styleUrls: ['./dynamic-search-page.component.css']
})

export class DynamicSearchPageComponent implements OnInit {

  public title: string;
  public vers: string;
  public isLoading: boolean;
  public isLoadingDetail: boolean;
  public isLoadingHistoryDetail: boolean;
  public vOthers = 0;
  public total = 0;
  public valueTotal = '';
  public linkToGif: string;
  public textHelp: string;
  public textNotification: string;
  public homeNotificationGif: string;
  public titleSearch: string;

  public lchartTotal: Array<PoChartSerie> = [];
  public ChartType: PoChartType = PoChartType.Donut;

  public items: Array<PoTableColumn> = [];
  public columns: Array<PoTableColumn> = [];
  public columnsFilter: Array<PoTableColumn> = [];

  public itemsDetails: Array<PoTableColumn> = [];
  public columnsDetails: Array<PoTableColumn> = [];
  public titleDetailsModal: string;

  public itemsHistoryDetails: Array<PoTableColumn> = [];
  public columnsHistoryDetails: Array<PoTableColumn> = [];
  public titleResultIdDetailsModal: string;

  public actions: Array<PoTableAction> = [];
  public actionsDetail: Array<PoTableAction> = [];
  public actionsHistory: Array<PoTableAction> = [];

  public breadcrumb: PoBreadcrumb;
  public hasNext: boolean;

  public filters: Array<PoDynamicFormField>;

  public pageActions: Array<PoPageAction> = [];
  public linkToImageIds: any;

  private service: any;
  private queryParams: any;
  private date: Date;
  public textHelpIds: any;
  public idsearch: any;
  public infoEnviromentForId = new ResultId();


  public close: PoModalAction = {
    action: () => {
      this.closeSearchId();
    },
    label: 'Cancelar',
    danger: true
  };

  public confirm: PoModalAction = {
    action: () => {
      this.proccessConsultReestuId();
    },
    label: 'Pesquisar',
    disabled: true,
  };

  public buttonResultiIdclose: string;
  public buttonResultiIdCompare: string;
  public buttonResultiIdAccessCentral: string;
  public dividerResultIdAmb: string;
  public dividerResultIdAtu: string;
  public infoResultIdId: string;
  public infoResultIdCod: string;
  public infoResultIdNome: string;
  public infoResultIdDate: string;
  public infoResultIdHour: string;
  public returnConsultId: boolean;
  public failResultId: string;
  public tabsDescriptions: { title: string; img: string; }[];

  @Input('p-service') set setService(service) {
    this.service = service;
  }

  @Input('p-name') set setName(name) {
    this.title = name;
  }

  @ViewChild('detail', { static: true }) detailsModalElement: PoModalComponent;
  @ViewChild('ResultConsultId', { static: true }) ResultConsultIdModalElement: PoModalComponent;
  @ViewChild('help', { static: true }) helpModalElement: PoModalComponent;
  @ViewChild('notification', { static: true }) notificationModalElement: PoModalComponent;
  @ViewChild('searchId', { static: true }) searchIdModalElement: PoModalComponent;
  @ViewChild('helpsearchId', { static: true }) helpsearchIdModalElement: PoModalComponent;

  constructor(
    private router: Router,
    public instservice: InstanciasService) { }

  ngOnInit() {

    this.breadcrumb = {
      items: [
        { label: 'Home', action: () => this.router.navigate(['/']) },
        { label: this.title }
      ]
    };

    this.actions.push(
      {
        action: this.openModal.bind(this),
        icon: 'po-icon-info',
        label: 'Detalhes'
      },
      {
        action: this.redirectCompare.bind(this),
        icon: 'po-icon-export',
        label: 'Acessar o Portal de Comparação de Ambientes'
      }
    );

    this.actionsDetail.push(
      {
        action: this.goToHistory.bind(this),
        icon: 'po-icon-eye',
        label: 'Visualizar'
      }
    );

    this.actionsHistory.push(
      {
        action: this.goToHistory.bind(this),
        icon: 'po-icon-eye',
        label: 'Visualizar'
      }
    );

    this.pageActions.push(
      {
        label: 'Acesso por ID.',
        action: this.openSearchId.bind(this),
        icon: 'po-icon-search',
      }
    );

    this.columns = this.service.getColumns();
    this.columnsDetails = this.service.getColumnsDetails();
    this.columnsHistoryDetails = this.service.getColumnsHistoryDetails();

    this.columnsFilter = this.service.getColumnsFilters();
    this.filters = this.columnsFilter.map(f => ({ property: f.property, label: f.label, type: f.type }));
    this.titleSearch = this.service.titleModalSearchId;
    this.buttonResultiIdclose = this.service.buttonResultiIdclose;
    this.buttonResultiIdCompare = this.service.buttonResultiIdCompare;
    this.buttonResultiIdAccessCentral  = this.service.buttonResultiIdAccessCentral;
    this.dividerResultIdAmb = this.service.dividerResultIdAmb;
    this.dividerResultIdAtu = this.service.dividerResultIdAtu;
    this.infoResultIdId = this.service.infoResultIdId;
    this.infoResultIdCod = this.service.infoResultIdCod;
    this.infoResultIdNome = this.service.infoResultIdNome ;
    this.infoResultIdDate = this.service.infoResultIdDate;
    this.infoResultIdHour = this.service.infoResultIdHour;
    this.tabsDescriptions = this.service.tabsDescriptions;

    this.getList({ token: this.instservice.userToken.token, page: 1 });

    if(this.checkToOpen()){
      this.textNotification = this.service.textNotification;
      this.homeNotificationGif = this.service.homeNotificationGif;
      this.notificationModalElement.open();
    }

  }

  redirectCompare(row: any) {
    const url = `${this.instservice.URL_COMPARE}?token=${this.instservice.userToken.token}&id=${row.customerid}`
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  getList(queryParams: any = {}) {
    this.queryParams = queryParams;
    this.isLoading = true;
    this.vers = this.queryParams.filter;
    this.service.get(queryParams).subscribe(
      next => {
        this.items = [...this.items, ...next.items];
        this.hasNext = !next.MetaInfo.LastPage;
        if (!this.vers) {
          this.lchartTotal = [];
          for (let index = 0; index < next.ListOfVersions.length; index++) {
            this.lchartTotal.push({
              label: next.ListOfVersions[index].version,
              data: Number(next.ListOfVersions[index].amount),
              tooltip: `Nº de Clientes: ${next.ListOfVersions[index].amount}`
            });
          }
        } else {
          this.lchartTotal = [];
          this.vOthers = 0;
          for (let index = 0; index < next.ListOfVersions.length; index++) {
            if (this.vers === next.ListOfVersions[index].version) {
              this.lchartTotal.push({
                label: next.ListOfVersions[index].version,
                data: Number(next.ListOfVersions[index].amount),
                tooltip: `Nº de Clientes: ${next.ListOfVersions[index].amount}`
              })
            } else {
              this.vOthers = this.vOthers + Number(next.ListOfVersions[index].amount)
            }
          }
          if (this.lchartTotal.length > 0) {
            this.lchartTotal.push({
              label: 'Outras',
              data: this.vOthers,
              tooltip: `Nº de Clientes: ${this.vOthers}`
            })
          } else {
            for (let index = 0; index < next.ListOfVersions.length; index++) {
              this.lchartTotal.push({
                label: next.ListOfVersions[index].version,
                data: Number(next.ListOfVersions[index].amount),
                tooltip: `Nº de Clientes: ${next.ListOfVersions[index].amount}`
              });
            }
          }
        }
        this.total = this.lchartTotal.reduce((total, valor) => total + Number(valor.data), 0);
        this.valueTotal = `Total de clientes por versão: ${this.total} `;
      },
      error => {
        this.instservice.handleError(error); // -- Avalia se retornou algum erro
        this.isLoading = false;
        if (error.error['Error'] == 'ExpiredToken' || error.error['Error'] == 'InvalidToken') {
          this.instservice.removesessionStorage(); /// -- Caso esteja expirado o Token sera removido o storage para o proximo refresh ser direcionado ao login
          //this.router.navigate(['']);
        }
      },
      complete => {
        this.isLoading = false;
      }
    );
  }

  onQuickSearch(filter) {
    this.items = [];
    this.getList({ token: this.instservice.userToken.token, page: 1, filter });
  }

  onChartSearch(filter) {
    console.log(filter);
    this.items = [];
    if (filter === 'Outras') {
      filter = '';
    }
    this.getList({ token: this.instservice.userToken.token, page: 1, filter });
  }

  onChangeDisclaimers(disclaimers) {
    this.items = [];
    const params = JSON.parse('{' + disclaimers.map(d => `"${d.property}": "${d.value}"`).join(', ') + '}');
    this.getList({ token: this.instservice.userToken.token, page: 1, ...params });
  }

  onAdvancedSearch(filters) {
    this.items = [];
    this.getList({ token: this.instservice.userToken.token, page: 1, ...filters });
  }

  onShowMore() {
    this.getList({ ...this.queryParams, page: 1 + (this.queryParams.page || 0) });
  }

  openModal(row) {
    this.titleDetailsModal = 'Environments existentes';
    this.itemsDetails = row.detail;
    this.detailsModalElement.open();
  }

  openModalHelp(this) {
    this.textHelp = this.service.textHelp;
    this.linkToGif = this.service.linkToGif;
    this.helpModalElement.open();
  }

  openModalHelpId(this) {
    this.linkToImageIds = this.service.linkToImageIds;
    this.textHelpIds = this.service.textHelpIds;
    this.helpsearchIdModalElement.open();
  }

  goToHistory(row) {
    window.open(
      row.redirect,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  openSearchId(){
    this.searchIdModalElement.open();
  }

  closeSearchId(){
    this.idsearch = undefined;
    this.searchIdModalElement.close();
  }

  proccessConsultReestuId(){
    this.getId({ token: this.instservice.userToken.token, id: this.idsearch });
  }

  closeModal() {
    this.notificationModalElement.close();
  }

  checkNotification(){
    var date = new Date();
    sessionStorage.setItem('Notification', date.toString() );
  }

  checkToOpen(){
    var ret: boolean = true;
    var dateRef = new Date();
    if(!!sessionStorage.getItem('Notification')){
      if (( Date.parse(dateRef.toString()) - Date.parse(sessionStorage.getItem('Notification')))<= 86400000 ) {
        ret = false;
      }
    }
    if(ret == true){
      this.clearNotification();
    }
    return ret
  }

  clearNotification(){
    sessionStorage.removeItem('Notification');
  }

  EventChangeNumber(id, ids){
    if(id !== null && id !== undefined){
      this.confirm.disabled = false;
    }else{
      this.confirm.disabled = true;
    }
  }

  getId(queryParams: any = {}){
    this.confirm.loading = true;
    this.infoEnviromentForId = {customerid: '', customername: '', dateupdated: '', hourupdated: '', redirect: '', request_id: ''};
    this.service.getSimple(queryParams).subscribe(
      next => {
        this.returnConsultId = next.Status;
        if(this.returnConsultId){
          this.infoEnviromentForId = next.Details;
          this.infoEnviromentForId.dateupdated = new Date(next.Details.dateupdated).toLocaleDateString('pt-BR');
          this.titleResultIdDetailsModal = 'Deseja acessar o cliente?';
        }else{
          this.titleResultIdDetailsModal = 'Id não encontrado';
          this.failResultId = this.service.failResultId;
        }
        this.confirm.loading = false;
        this.ResultConsultIdModalElement.open();
      },
      error => {
        this.instservice.handleError(error);
        this.confirm.loading = false;
      },
    )
  }

  closeModalResultConsultId() {
    this.ResultConsultIdModalElement.close();
  }

  goToLink(link) {
    window.open(
      link,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  goRedirectCompare(customerid) {
    const url = `${this.instservice.URL_COMPARE}?token=${this.instservice.userToken.token}&id=${customerid}`
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
}
