var currentUrl = window.location.href;
if (/^.*localhost.*/.test(currentUrl)) {
  var API_BASE_URL = 'http://localhost:81/patchweb/portal/'
  var URL_FRONT_COMPARE = 'http://localhost:4200/'
} else if (/^.*tph.engpro.totvs.com.br*/.test(currentUrl)) {
  var API_BASE_URL = 'https://api.tph.engpro.totvs.com.br/portal/'
  var URL_FRONT_COMPARE = 'https://compare.tph.engpro.totvs.com.br/'
} else if (/^.*tph-homolog.engpro.totvs.com.br*/.test(currentUrl)) {
  var API_BASE_URL = 'https://api.tph-homolog.engpro.totvs.com.br/portal/'
  var URL_FRONT_COMPARE = 'https://compare.tph-homolog.engpro.totvs.com.br/'
} else if (/^.*tph.pre.engpro.totvs.com.br*/.test(currentUrl)) {
  var API_BASE_URL = 'https://api.tph.pre.engpro.totvs.com.br/portal/'
  var URL_FRONT_COMPARE = 'https://compare.tph.pre.engpro.totvs.com.br/'
}else{
  var API_BASE_URL = 'https://api.tph.engpro.totvs.com.br/portal/'
  var URL_FRONT_COMPARE = 'https://compare.tph.engpro.totvs.com.br/'
}

export const environment = {
  production: true,
  url_api: API_BASE_URL,
  url_front_compare: URL_FRONT_COMPARE,
};


