import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PoChartType, PoChartSerie} from '@po-ui/ng-components';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {

    @Output() sendEventFilter = new EventEmitter<any>();

    @Input() chartTotal: Array<PoChartSerie> = [];

    @Input() valueTotal = '';

    @Input() ChartType: PoChartType;

    constructor() {}

  ngOnInit() {
  }

  SendEvent(event: any){
    this.sendEventFilter.emit(event.label);
  }

}
