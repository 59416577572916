import { Component } from '@angular/core';
import { PoMenuItem, PoToolbarAction } from '@po-ui/ng-components';
import { Authguard } from './core/auth/auth.guard';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  readonly menus: Array<PoMenuItem> = [];
  notificationActions: Array<PoToolbarAction> = [];
  mostraMenu: boolean = false;
  menuAd: boolean = false;

  constructor(private authservice: Authguard) {

    this.menus = [
      { label: 'Home', icon: 'po-icon po-icon-home', shortLabel: 'Home', link: '/' },
    ];
  }

  ngOnInit() {
    this.authservice.mostraMenuEmit.subscribe(
      mostrar => this.mostraMenu = mostrar
    );
    this.authservice.menuAdmin.subscribe(
      () => this.menuAlter()
    );
  }

  menuAlter() {
    this.menus.push(
      { label: 'Expedição', icon: 'po-icon po-icon-archive', shortLabel: 'Exped.', subItems: [
            { label: 'Acumulado', link: '/approve' },
            { label: 'Artefatos', link: '/artifacts' },
            { label: 'Binário', link: '/binary' }
          ]},
      { label: 'Configurações', icon: 'po-icon po-icon-settings', shortLabel: 'Config.', subItems: [
            { label: 'Acessos', link: '/access' },
            { label: 'Central de notificações', icon: 'po-icon po-icon-settings', shortLabel: 'Notificações.', subItems: [
              { label: 'Categorias', link: '/notifications-categories' },
              { label: 'Configurações das notificações', link: '/notifications-config' },
            ]},
          ]},
      );
  }

}
