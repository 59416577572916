import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpService } from '../core/services/http.service';

@Injectable()
export class HomeService extends HttpService {

  constructor(
    http: HttpClient) {

    super(http);

    this.endpoint = 'portal_customers.php';
    this.endpointdetail = 'consultrequestid.php';
    this.literalContext = 'customers';
    this.linkToGif = 'assets/gif/home.gif';
    this.homeNotificationGif = 'assets/gif/homeNotification.gif';
    this.titleModalSearchId = 'Acessar central de atualização por Id.';
    this.linkToImageIds = 'assets/gif/CentralId.gif';
    this.dividerResultIdAmb = 'Ambiente';
    this.dividerResultIdAtu = 'Atualização da central';
    this.infoResultIdId = 'Id';
    this.infoResultIdCod = 'Codigo T.';
    this.infoResultIdNome = 'Nome do cliente';
    this.infoResultIdDate = 'Data';
    this.infoResultIdHour = 'Hora';
    this.buttonResultiIdclose = 'Cancelar';
    this.buttonResultiIdCompare = 'Central de comparação';
    this.buttonResultiIdAccessCentral  = 'Central de atualização';
    this.failResultId = 'Não foi possível encontrar o Id informado. Por favor, verifique o Id informado e tente novamente.';
    this.tabsDescriptions =  [
      { title: 'Como consultar por Id?', img: 'assets/gif/PortalTphConusltaPorId.gif' },
      { title: 'Como consultar por Cliente?', img: 'assets/gif/PortalTphConusltaPorCliente.gif'} ];

    this.textHelpIds = `
      <p>Repositórios atualizados com a LIB a partir da versão 20220502, será disponibilizado o código Id. da central de atualizações, consultando nas propriedades de informações do sistema no Protheus.</p>
      <br>
      <p>Com esse Id. será possível efetuar a consulta da central de atualização ou a central de comparação.</p>
      <br>
      `
    this.textHelp =
      `
        <strong>Informações gerais sobre Portal da central de atualização.</strong>
        <br>
        <br>
        <p class="text-justify"O portal da central de atualização é uma centralizadora dos clientes contidos na nossa base de dados. Lembrando que esta ferramenta é de uso interno para analistas Totvs, neste portal há a possibilidade de listar todos os clientes que já utilizam a central de atualização em seus repositórios, com isso podemos entrar na central do cliente ou na central de comparação de ambientes.</p>
        <br>
        <p class="text-justify">Na tela inicial do portal será listado todos os clientes contidos na base de dados da central de atualização, permitindo o analista fazer a busca do cliente desejado.</p>
      `
      this.textNotification =
      `
        <br>
        <p class="text-justify">Houve alteração na visualização do portal, agora para realizar a consulta do histórico de envio de um servidor do ambiente do cliente, deverá ser realizada a consulta na 'Comparação de ambiente.'</p>
        <p class="text-justify">Para realizar a consulta do histórico, selecione um ambiente do cliente, entre em: <b>'Acessar o Portal de Comparação de Ambientes'</b></p>
        <br>
      `
    this.columns = [
      { property: 'customerid', label: "Id Cliente" },
      { property: 'customername', label: "Nome" },
      { property: 'totvsid', label: "Id Instalação" }
    ];

    this.columnsDetails = [
      { property: 'customerenvironment', label: "Ambiente" , width: '250px'  },
      { property: 'customerversion', label: "Versão" , width: '200px' },
      { property: 'customerserver', label: "Servidor" , width: '200px' },
      { property: 'customerport', label: "Porta" , width: '150px' }
    ];

    this.columnsHistoryDetails = [
      { property: 'r_id', label: 'Id Operação', width: '250px' },
      { property: 'r_data', label: 'Data Criação', type: 'date', format: 'dd/MM/yyyy', width: '150px' },
      { property: 'r_hora', label: 'Hora Patch', type: 'time', format: 'HH:mm:ss', width: '150px'  },
    ];

    this.columnsFilters = [
      { property: 'customername', label: "Nome" },
      { property: 'topologyId', label: "Id da Topologia" }
    ]

  }
}
