import { NgModule } from '@angular/core';

import { PoDynamicModule, PoMenuModule, PoToolbarModule } from '@po-ui/ng-components';
import { PoPageModule } from '@po-ui/ng-components';
import { SharedModule } from '../shared/shared.module';

import { HomeComponent } from './home.component';
import { HomeService } from './home.service';

//--Retirar apos criar component de notificação
import { PoListViewModule } from '@po-ui/ng-components';
import { PoModalModule } from '@po-ui/ng-components';

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    SharedModule,
    PoDynamicModule,
    PoPageModule,
    PoListViewModule,
    PoModalModule,
    PoMenuModule,
    PoToolbarModule,
  ],
  providers: [
    HomeService
  ],
  exports:[
    HomeComponent
  ]
  
})
export class HomeModule { }
