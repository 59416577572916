
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { Authguard } from './core/auth/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [Authguard]
  },
  {
    path: 'access',
    loadChildren: () => import('./settings/access/access.module').then(m => m.AccessModule),
    canActivate: [Authguard],
    data: { authGuard: { redirect: '/login' } }
  },
  {
    path: 'approve',
    loadChildren: () => import('./shipment/approve/approve.module').then(m => m.ApproveModule),
    canActivate: [Authguard],
    data: { authGuard: { redirect: '/login' } }
  },
  { path: 'binary',
    loadChildren: () => import('./shipment/binary/binary.module').then(m => m.BinaryModule),
    canActivate: [Authguard],
    data: { authGuard: { redirect: '/login' } }
  },
  {
    path: 'notifications-config',
    loadChildren: () => import('./settings/notifications/notifications-config/notifications-config.module').then(m => m.NotificationsConfigModule),
    canActivate: [Authguard],
    data: { authGuard: { redirect: '/login' } }
  },
  {
    path: 'notifications-details/notificationsdetails',
    loadChildren: () => import('./settings/notifications/notifications-config/notifications-details/notifications-details.module').then(m => m.NotificationsDetailsModule),
    canActivate: [Authguard],
    data: { authGuard: { redirect: '/login' }}
  },
  { path: 'artifacts', loadChildren: () => import('./shipment/artifacts/artifacts.module').then(m => m.ArtifactsModule), canActivate: [Authguard], data: { authGuard: { redirect: '/login' } } },
  { path: 'notifications-categories', loadChildren: () => import('./settings/notifications/notifications-categories/notifications-categories.module').then(m => m.NotificationsCategoriesModule), canActivate: [Authguard], data: { authGuard: { redirect: '/login' } } },
  {
    path: '**',
    redirectTo: '/login'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
