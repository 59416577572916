<div class="po-wrapper" >
    <po-toolbar p-title="Portal da Central de Atualizações - Protheus"
        p-notification-icon="po-icon po-icon-notification" [p-notification-actions]="notificationActions" *ngIf="mostraMenu">
    </po-toolbar>

    <po-menu [p-menus]="menus" *ngIf="mostraMenu"></po-menu>

    <router-outlet></router-outlet>

</div>

