import { EventEmitter, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InstanciasService } from 'src/app/app.service';

@Injectable({ providedIn: 'root'})
export class Authguard implements CanActivate {

    mostraMenuEmit = new EventEmitter<boolean>();
    menuAdmin = new EventEmitter<boolean>();

    constructor(
        private router: Router,
        public instservice: InstanciasService) {}

    canActivate(
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
            if(!this.instservice.isLogged()){
                this.mostraMenuEmit.emit(false);
                this.router.navigate(['/login']);
                return false;
            } else {
                if(this.instservice.isGroupAdmin()) {
                    this.menuAdmin.emit(true);
                    this.menuAdmin.complete();
                }  
                this.mostraMenuEmit.emit(true);
                this.mostraMenuEmit.complete();
                return true;
            }
    }
    
    
}