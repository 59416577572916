import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { PoPageLoginModule, PoTemplatesModule } from '@po-ui/ng-templates';
import { PoModalPasswordRecoveryModule } from '@po-ui/ng-templates';
import { PoLoadingModule, PoMenuModule, PoToolbarModule } from '@po-ui/ng-components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoginComponent } from './login/login.component';
import { HomeModule } from './home/home.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Authguard } from './core/auth/auth.guard';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PoPageLoginModule,
    PoModalPasswordRecoveryModule,
    PoLoadingModule,
    HomeModule,
    PoTemplatesModule,
    PoToolbarModule,
    PoMenuModule,
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
  ],
  providers: [
    Authguard
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
