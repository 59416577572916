<po-page-login
  p-hide-remember-user
  [p-loading]="loading"
  [p-literals]="customLiterals"
  (p-login-submit)="checkLogin($event)"
  p-logo="assets/images/totvs-logo-page-login.svg">
</po-page-login>

<div class="sample-container" *ngIf="loading">
  <po-loading-overlay></po-loading-overlay>
</div>