import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PoPageLogin, PoPageLoginLiterals } from '@po-ui/ng-templates';

import { InstanciasService } from '../app.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {

	userLogin: string;
	userPass: string;
	customLiterals: PoPageLoginLiterals;
	isLogged: boolean = false;
	loading: boolean = false;

	constructor(
		private router: Router,
		public instservice: InstanciasService) { }

	ngOnInit() { 
		this.customLiterals = {
			loginPlaceholder: 'Insira seu usuário (Ex:jose.silva)'
		};

	}

	checkLogin(formData: PoPageLogin) {
		this.loading = true;

		this.instservice.userToken.username = formData.login;
		this.instservice.userToken.password = formData.password;
		this.instservice.userToken.token = '';
		this.instservice.userToken.usergroup = '';

		this.instservice.getToken(this.instservice.userToken.username, this.instservice.userToken.password)
			.subscribe(
				token => {
					this.instservice.userToken.token = token;
					this.isLogged = true;
					this.router.navigate(['']);
				},
				error => {
					this.instservice.handleError(error);
					this.isLogged = false;
				}
			);
		this.loading = false;


	}

}
