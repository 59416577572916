import { Component, ViewChild } from '@angular/core';

import { HomeService } from './home.service';
import { PoModalComponent } from '@po-ui/ng-components';
import { Authguard } from '../core/auth/auth.guard';
@Component({
  selector: 'ap-customer',
  providers: [HomeService],
  templateUrl: './home.component.html'
})

export class HomeComponent {
  mostraMenu: boolean;
  constructor(
    public homeService: HomeService,
    private authservice: Authguard) {}

  @ViewChild('notificationListModal', { static: true }) notificationListModal: PoModalComponent;

  ngOninit(){
    this.authservice.mostraMenuEmit.subscribe(
      mostrar => this.mostraMenu = mostrar
    )
  }

}
