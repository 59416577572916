<po-page-dynamic-search
  [p-title]="title"
  [p-breadcrumb]="breadcrumb"
  [p-filters]="filters"
  [p-actions]="pageActions"
  (p-quick-search)="onQuickSearch($event)"
  (p-change-disclaimers)="onChangeDisclaimers($event)"
  (p-advanced-search)="onAdvancedSearch($event)"
>
  <div class="icon-help">
    <span class="po-icon po-icon-help" (click)="openModalHelp()"></span>
  </div>
  <div style="position: relative">
    <div class="po-row">
      <po-container class="po-row">
        <div class="po-font-text-bold">Clientes por versão</div>
        <app-chart
          (sendEventFilter)="onChartSearch($event)"
          [chartTotal]="lchartTotal"
          [valueTotal]="valueTotal"
          [ChartType]="ChartType"
        ></app-chart>
      </po-container>
    </div>
    <div class="po-row">
      <po-container class="po-row">
        <div class="po-font-text-bold">Lista de clientes</div>
        <po-table
          p-actions-right="true"
          p-container
          p-sort="true"
          p-striped="true"
          p-container="shadow"
          [p-actions]="actions"
          [p-columns]="columns"
          [p-items]="items"
          [p-loading]="isLoading"
          [p-show-more-disabled]="!hasNext"
          (p-show-more)="onShowMore()"
        >
        </po-table>
      </po-container>
    </div>
    <po-loading-overlay [hidden]="!isLoading"></po-loading-overlay>
  </div>
</po-page-dynamic-search>

<po-modal #detail p-size="auto" p-title="{{ titleDetailsModal }}">
  <po-table
    p-actions-right="true"
    p-container
    p-sort="true"
    p-striped="true"
    p-container="shadow"
    [p-actions]="actionsDetail"
    [p-columns]="columnsDetails"
    [p-items]="itemsDetails"
  >
  </po-table>
</po-modal>

<po-modal #help p-size="auto" p-title="{{ title }}">
  <po-tabs>
    <po-tab p-active p-label="Resumo">
      <div [innerHTML]="textHelp"></div>
    </po-tab>
    <po-tab *ngFor='let item of tabsDescriptions' [p-label]="item.title">
      <div class="imgcenter">
        <img [attr.src]="item.img" />
      </div>
    </po-tab>
  </po-tabs>
</po-modal>

<po-modal #notification p-size="md" p-title="Atenção">
  <po-tabs>
    <po-tab p-active p-label="Resumo">
      <div [innerHTML]="textNotification"></div>
    </po-tab>
    <po-tab p-label="Como consultar?">
      <img [attr.src]="homeNotificationGif" />
    </po-tab>
  </po-tabs>
  <po-modal-footer [p-disabled-align]="false">
    <div class="po-row">
      <po-checkbox
        class="po-xl-3 po-lg-3 po-md-4 po-sm-6"
        (p-change)="checkNotification()"
        name="checkbox"
        p-label="Não mostrar hoje"
      >
      </po-checkbox>
      <po-button
        class="po-offset-lg-7 po-offset-xl-7 po-xl-2 po-lg-2 po-md-4 po-sm-6"
        p-type="danger"
        p-label="Fechar"
        (p-click)="closeModal()"
      >
      </po-button>
    </div>
  </po-modal-footer>
</po-modal>

<po-modal
  #searchId
  p-size="lg"
  p-title="{{ titleSearch }}"
  [p-primary-action]="confirm"
  [p-secondary-action]="close"
  p-hide-close="true"
>
  <div class="icon-help">
    <span class="po-icon po-icon-help" (click)="openModalHelpId()"></span>
  </div>
  <po-number
    class="po-offset-lg-3 po-offset-xl-3 po-offset-md-3 po-offset-sm-3 po-lg-6 po-lg-6 po-md-6 po-sm-6"
    p-label="Informe o Id "
    p-clean
    (p-change-model)="EventChangeNumber($event, this)"
    [(ngModel)]="idsearch"
  >
  </po-number>
</po-modal>

<po-modal #helpsearchId p-size="lg" p-title="{{ titleSearch }}" p-click-out="true">
  <po-tabs>
    <po-tab p-active p-label="Resumo">
      <div [innerHTML]="textHelpIds"></div>
    </po-tab>
    <po-tab p-label="Como consultar?">
      <div class="imgcenter">
        <img [attr.src]="linkToImageIds" />
      </div>
    </po-tab>
  </po-tabs>
</po-modal>

<po-modal
  #ResultConsultId
  p-size="xl"
  p-title="{{ titleResultIdDetailsModal }}"
  p-click-out="true">
  <div *ngIf="returnConsultId; else fail">
    <po-divider [p-label]="dividerResultIdAmb" ></po-divider>
    <po-tag class="po-lg-1 po-lg-1 po-md-2 po-sm-3" [p-label]="infoResultIdId" [p-value]="infoEnviromentForId.request_id" p-color="color-01">
    </po-tag>
    <po-info class="po-lg-2 po-lg-2 po-md-2 po-sm-2" [p-label]="infoResultIdCod" [p-value]="infoEnviromentForId.customerid">
    </po-info>
    <po-info class="po-lg-9 po-lg-9 po-md-8 po-sm-7" [p-label]="infoResultIdNome" [p-value]="infoEnviromentForId.customername">
    </po-info>
    <po-divider [p-label]="dividerResultIdAtu" >
    </po-divider>
    <po-info class="po-lg-3 po-lg-3 po-md-4 po-sm-5" [p-label]="infoResultIdDate" [p-value]="infoEnviromentForId.dateupdated">
    </po-info>
    <po-info class="po-lg-6 po-lg-6 po-md-6 po-sm-6" [p-label]="infoResultIdHour" [p-value]="infoEnviromentForId.hourupdated">
    </po-info>
    <po-modal-footer [p-disabled-align]="false">
      <po-button p-type="danger" [p-label]="buttonResultiIdclose" (p-click)="closeModalResultConsultId()"> </po-button>
      <po-button p-type="default" [p-label]="buttonResultiIdCompare" (p-click)="goRedirectCompare(infoEnviromentForId.customerid)"> </po-button>
      <po-button p-type="primary" [p-label]="buttonResultiIdAccessCentral" (p-click)="goToLink(infoEnviromentForId.redirect)"> </po-button>
    </po-modal-footer>
  </div>
  <ng-template #fail>
    <div class="po-row">{{failResultId}}</div>
  </ng-template>

</po-modal>
