import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module'

import { PoPageDynamicSearchModule } from '@po-ui/ng-templates';
import { PoTabsModule, PoTableModule, PoModalModule, PoButtonModule, PoFieldModule, PoLoadingModule, PoInfoModule, PoTagModule, PoDividerModule } from '@po-ui/ng-components';
import { DynamicSearchPageComponent } from './components/dynamic-search-page/dynamic-search-page.component';
import { ChartModule } from './components/chart/chart.module';
import { PoContainerModule } from '@po-ui/ng-components';

@NgModule({
  declarations: [DynamicSearchPageComponent],
  imports: [
    CommonModule,
    CoreModule,
    PoPageDynamicSearchModule,
    PoTableModule,
    PoModalModule,
    FormsModule,
    PoTabsModule,
    ChartModule,
    PoContainerModule,
    PoButtonModule,
    PoFieldModule,
    PoLoadingModule,
    PoInfoModule,
    PoTagModule,
    PoDividerModule,
  ],
  exports: [
    DynamicSearchPageComponent,
  ],
})
export class SharedModule { }

