import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PoDynamicFormValidation, PoSelectOption, PoTableColumn } from '@po-ui/ng-components';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private readonly URL_API = environment.url_api;

  protected endpoint: string;
  protected endpointdetail: string;
  protected endpointpost: string;
  protected endpointcrud: string;
  protected literalContext: string;
  protected linkToGif: string;
  protected textHelp: string;
  protected titleHead: string;
  protected titleModalHom: string;
  protected buttonMoreoptions: string;
  protected labelCombo1: string;
  protected labelCombo2: string;
  protected nameRef1: string;
  protected nameRef2: string;
  protected nameRef3: string;
  protected nameRef4: string;
  protected nameRef5: string;
  protected nameRef6: string;
  protected labelRadio: string;
  protected columns: Array<PoTableColumn>;
  protected columnsDetails: Array<PoTableColumn>;
  protected columnsHistoryDetails: Array<PoTableColumn>;
  protected columnsFilters: Array<PoTableColumn>;
  protected optionsGroups: Array<PoSelectOption>;
  protected optionsStatus: Array<PoSelectOption>;
  protected textNotification: string;
  protected homeNotificationGif: string;
  protected titleModalSearchId: string;
  protected linkToImageIds: string;
  protected textHelpIds: string;
  protected dividerResultIdAmb: string;
  protected dividerResultIdAtu: string;
  protected dividerPageFirst: string;
  protected dividerPageSecond: string;
  protected dividerPageThird: string;
  protected infoResultIdId: string;
  protected infoResultIdCod: string;
  protected infoResultIdNome: string;
  protected infoResultIdDate: string;
  protected infoResultIdHour: string;
  protected buttonResultiIdclose: string;
  protected buttonResultiIdCompare: string;
  protected buttonResultiIdAccessCentral: string;
  protected failResultId: string;
  protected tabsDescriptions: { title: string; img: string; }[];
  protected title: string;
  protected titleSlidePage: string;
  protected subtitleSlidePage: string;
  protected descriptionSlidePage: string;
  protected descriptionSlidePageAlternative: string;
  protected textSucess: string;
  protected textError: string;
  protected textWaiting: string;
  protected textStartProcess: string;
  protected textPreview: string;
  protected textEndProcess: string;
  protected nameRefTitleNotification: string;
  protected titleNotification: string;
  protected objectButtons: {name: string, disabled: boolean, action?: Function, icon: string, kind: string, property: string, stage: Array<String>}[];
  protected objCreateReferenceList: { option: string; type: string; list: string; messageConfirm: string; propertyForms?: string; valueForms?: number; }[];
  protected objForFormDynamic: Array<any>;
  protected objChangedFields: { property_condition: Array<String>; obj: PoDynamicFormValidation }[];
  protected validateFields: Array<String>;


  constructor(
    private http: HttpClient) { }

  get(queryParams = {}): Observable<any> {
    const params = new HttpParams({
      fromObject: { ...queryParams, fields: this.getColumns().map(f => f.property).join(',') }
    });
    return this.http.get(`${this.URL_API}${this.endpoint}`, { params });
  }

  getDetail(queryParams = {}): Observable<any> {
    const params = new HttpParams({
      fromObject: { ...queryParams, fields: this.getColumnsDetails().map(f => f.property).join(','), lang: navigator.language }
    });
    return this.http.get(`${this.URL_API}${this.endpointdetail}`, { params },);
  }

  getSimple(queryParams = {}): Observable<any> {
    const params = new HttpParams({
      fromObject: { ...queryParams }
    });
    return this.http.get(`${this.URL_API}${this.endpointdetail}`, { params },);
  }

  post(obj): Observable<any> {
    return this.http.post(`${this.URL_API}${this.endpointpost}`, obj);
  }

  postDetail(obj): Observable<any> {
    return this.http.post(`${this.URL_API}${this.endpointdetail}`, obj);
  }

  postcrud(obj): Observable<any> {
    return this.http.post(`${this.URL_API}${this.endpointcrud}`, obj);
  }

  deletecrud(queryParams = {}): Observable<any> {
    const params = new HttpParams({
      fromObject: { ...queryParams }
    });
    return this.http.delete(`${this.URL_API}${this.endpointcrud}`, { params },);
  }

  getColumns(): Array<PoTableColumn> {
    return this.columns;
  }

  getColumnsDetails(): Array<PoTableColumn> {
    return this.columnsDetails;
  }

  getColumnsHistoryDetails(): Array<PoTableColumn> {
    return this.columnsHistoryDetails;
  }

  getColumnsFilters(): Array<PoTableColumn> {
    return this.columnsFilters;
  }

  getOptionsGroups(): Array<PoSelectOption> {
    return this.optionsGroups;
  }

  getOptionsStatus(): Array<PoSelectOption> {
    return this.optionsStatus;
  }

}
