import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap, retry } from 'rxjs/operators';

import { PoNotificationService } from '@po-ui/ng-components';
import { Token } from './class/Token';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class InstanciasService {

    public userToken = new Token();

    public readonly URL_COMPARE = environment.url_front_compare;
    private readonly URL_API = environment.url_api;

    public httpOptions = {
        headers: new HttpHeaders({
            'Content-type': 'application/json',
            'Authorization': this.userToken.token || 'tokeninvalido'
        })
    };
    constructor(private _http: HttpClient, private poNotification: PoNotificationService) { }

    getToken(username, password): Observable<any> {
        return this._http.post<any>(this.URL_API + 'login.php', JSON.stringify({ User: username, Password: password }), this.httpOptions)
            .pipe(
                retry(3),
                map(res => {
                    console.log(res);
                    this.userToken.token = res.Token;
                    this.userToken.usergroup = res.Grupo;
                    this.updateHeaders();
                    sessionStorage.setItem('Token', this.userToken.token);
                    sessionStorage.setItem('User', this.userToken.username);
                    sessionStorage.setItem('Group', this.userToken.usergroup);
                    return this.userToken.token;
                }),
                catchError(err => this.handleError(err) )
            );
    }

    updateHeaders() {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-type': 'application/json',
                'Authorization': this.userToken.token
            })
        };
    }

    isLogged() {
        if(!this.userToken.token && !!sessionStorage.getItem('Token')) {
            this.userToken.token = sessionStorage.getItem('Token');
        }
        return !!(sessionStorage.getItem('Token')); //Retorna se está logado.
    }

    removesessionStorage(){
        sessionStorage.removeItem('Token');
        sessionStorage.removeItem('User');
        sessionStorage.removeItem('Group');
    }

    restoresessionStorage(){
        this.userToken.token = sessionStorage.getItem('Token');
        this.userToken.usergroup = sessionStorage.getItem('Grupo')
    }

    handleError(err: HttpErrorResponse) {
		console.log(err);
		console.log(err.error);
        if(err.status == 500){
            this.poNotification.error(err.status + ' - ' + err.statusText)
        }else{
            this.poNotification.error(err.error["Error"]);
        }

        return throwError('Não foi possível concluir a solicitação');
    }

    bodyError(err) {
		console.log(err);
		console.log(err.error);
        this.poNotification.error(err.Error);
        return throwError('Não foi possível concluir a solicitação');
    }

    ErrorString(err: string) {
          this.poNotification.error(err);
      }

    bodySuccess(msg: string) {
      this.poNotification.success(msg);
    }

    isGroupAdmin(){
        if(this.userToken.usergroup == 'Admin' || sessionStorage.getItem('Group') == 'Admin' ){
            return true
        }
        return false
    }
}
